import styles from "./style.module.scss";
import apexGameInfo from "src/assets/json/apexGameInfo.json";
import { GameMode } from "src/utils/types/roomTypes";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface Props {
  type: GameMode;
  map: string;
}

const ApexMap: React.FunctionComponent<Props> = (props) => {
  const maps = apexGameInfo[props.type] && apexGameInfo[props.type].maps;

  return (
    <TransformWrapper initialScale={1} maxScale={10} centerOnInit={true}>
      <TransformComponent>
        <img
          className={styles.mapCanvas}
          src={maps[props.map as keyof typeof maps]?.mapImage}
          alt={props.map}
        />
      </TransformComponent>
    </TransformWrapper>
  );
};

export default ApexMap;
